import { Box, TextField } from "@mui/material";
import { useState, useRef } from "react";

const WorkspaceInput = (props) => {
  const { formik } = props;
  const [workspace, setWorkspace] = useState("");
  const inputRef = useRef(null);

  return (
    <Box sx={{ position: "relative", width: "100%", maxWidth: 400 }}>
      {/* Overlay full domain */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          paddingLeft: "14px",
          fontSize: "16px",
          color: "rgba(0, 0, 0, 0.4)",
          pointerEvents: "none",
          overflow: "hidden",
        }}
      >
        <Box component="span" sx={{ color: "transparent", whiteSpace: "pre" }}>
          {formik?.values?.customDomain || "mydomain"}
        </Box>
        <Box component="span" sx={{ color: "rgba(0,0,0,0.5)" }}>
          .icod.ai
        </Box>
      </Box>

      {/* Actual input */}
      <TextField
        inputRef={inputRef}
        name="customDomain"
        placeholder="mydomain"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        fullWidth
        value={formik.values.customDomain}
        variant="outlined"
        error={
          formik.touched?.customDomain && Boolean(formik.errors?.customDomain)
        }
        InputProps={{
          style: {
            position: "relative",
            zIndex: 1,
            background: "transparent",
          },
        }}
        sx={{
          input: {
            color: "#000",
            background: "transparent",
            position: "relative",
            zIndex: 1,
          },
        }}
      />
    </Box>
  );
};

export default WorkspaceInput;
