import { Box, Typography } from '@mui/material';
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import MasterCard from "../../../../assets/images/MasterCard.png";
import { Mail } from "lucide-react";

const PaymentMethodCard = (props) => {
  const { t, ProfileData } = props;
  const formatExpiry = (expiry) => {
    if (!expiry) return "-";
    const expiryStr = expiry.toString().padStart(4, "0");
    const month = expiryStr.slice(0, 2);
    const year = "20" + expiryStr.slice(2); // assuming it's 2000s
    return `${month}/${year}`;
  };

  const formatCardNumber = (card) => {
    if (!card || card.length < 5) return ""; // basic safety

    const firstDigit = card[0];
    const lastFour = card.slice(-4).toLowerCase(); // get last 4 characters
    const maskedPart = `${firstDigit}*** **** ****`;

    // Show real last 4 digits only if they are not "xxxx"
    const showLastFour = lastFour !== "xxxx" ? lastFour : "****";

    return `${maskedPart} ${showLastFour}`;
  };
  return (
    <Box sx={BoxStyle}>
      <Box sx={CardHeaderStyle}>
        <Typography variant='h5' sx={{ fontWeight: 500 }} >
          {t("Dashboard.Current_Payment_Method")}
        </Typography>
      </Box>
      <Box sx={CardDetailContainerStyle}>
        <Box sx={InnerContainerStyle}>
          <Box sx={CardDetailStyle}>
            <img
              src={MasterCard}
              style={{ width: "32px", height: "21px" }}
            />
            <Box sx={CardTextStyle}>
              <Typography variant='h5' sx={{ fontWeight: 500 }} >
                {ProfileData?.card_type}
              </Typography>
              <Typography variant='h5' sx={{ fontWeight: 500, fontFamily: 'monospace', letterSpacing: '0.05em' }} >

                {formatCardNumber(ProfileData?.card)}
                {/* **** **** **** 4002 */}
              </Typography>
              <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                Expiry on {formatExpiry(ProfileData?.card_expiry)}
              </Typography>
              <Box sx={BillingStyle}>
                <Mail width={12} height={12} />
                <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                  {ProfileData?.email}
                </Typography>
              </Box>
            </Box>

          </Box>
          <OutlinedButton
            text={t("Dashboard.change")}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentMethodCard;

const BoxStyle = {
  width: "437px",
  height: "228px",
  display: "flex",
  flexDirection: "column"
}

const CardHeaderStyle = {
  width: "100%",
  display: "flex",
  height: "56px",
  alignItems: "center",
  padding: "8px 16px",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  border: "1px solid #DFE4EA",
  backgroundColor: "#F3F4F6",
}

const CardDetailContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "172px",
  padding: "16px",
  border: "1px solid #DFE4EA",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
}

const InnerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

}

const CardDetailStyle = {
  display: "flex",
  gap: "16px",
}

const CardTextStyle = {
  display: "flex",
  gap: "8px",
  flexDirection: "column",
}

const BillingStyle = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
}